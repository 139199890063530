import { takeEvery, all, call, put, select, take } from 'redux-saga/effects';
import * as router from '../router';
import * as routerUtils from '../router/routers/router-utils';
import * as app from '../app';
import * as auth from '../auth';
import * as board from '../board';
import { requestApi, isNetworkError } from '../../request-api';
import { show as showSnackBar } from '../../components/kerika-snackbar.js';
import i18next from '@dw/i18next-esm';
import { openExternalLink } from '../../components/open-external-link.js';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import filter from 'lodash-es/filter';
import findIndex from 'lodash-es/findIndex';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import * as actions from './actions';
import firestoreRedux from '@dreamworld/firestore-redux';
import { getIdWoPrefix, getNewOrder } from '../../utils';
import entityIdProvider from '../../entity-id-provider';
import * as knownFeatures from '../known-features';
import * as fileStore from '../file-store/index.js';
import { store } from '../../store';
import { downloadUrl } from '../../components/utils';
import { ReduxUtils } from '@dw/pwa-helpers/redux-utils';
import * as selectors from './selectors';
import * as attachmentUtils from './utils.js';

/**
 * Refresh file-store permission for current board.
 * After successfull refresh, loads attachment content url, text resources & shows toast message with action button.
 */
function* onLinkAdditionalAuthSuccess() {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);
  const cardId = router.selectors.cardId(state);
  const attachmentId = get(state, `router.page.params.file-preview`, '');
  if (!attachmentId || !boardId) {
    return;
  }
  try {
    yield call(requestApi, `/file-store/refresh-board-folder-permission/${boardId}`, { method: 'PUT', excludeErrors: [409, 304] });
    const firestorePath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    const attachmentRequest = firestoreRedux.getDocById(firestorePath, attachmentId, { once: true, waitTillSucceed: true });
    const response = yield attachmentRequest.result;
    const contentUrl = get(response, 'url', '');
    if (contentUrl) {
      //When BOX + Installed app, text resources is not loaded so loads it first.
      yield call(app.actions.initI18n);
      yield call(i18next.loadNamespaces.bind(i18next), ['attachments']);

      //Remove edit dialog.
      const filePreviewAction = get(router.selectors.page(state), 'params.file-preview-action', '');
      if (filePreviewAction === 'edit') {
        router.actions.back();
      }

      showSnackBar({
        message: i18next.t('attachments:filePreview.toast.successMessage'),
        actionButton: {
          caption: i18next.t('attachments:filePreview.toast.button'),
          callback: () => {
            openExternalLink({ href: contentUrl }, attachmentId);
          },
        },
      });
    }
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }
    console.error('Failed to show file-preview success toast:', error);
  }
}

/**
 * Loads board attachments, account cloud stores & clound store emails from firestore
 */
function* loadBoardAttachments({ boardId }) {
  const state = yield select();

  // If query is live already, returns.
  const requesterId = `board-attachments-${boardId}`;
  const queryStatus = firestoreRedux.selectors.queryStatus(state, requesterId);
  if (queryStatus === 'PENDING' || queryStatus === 'LIVE') {
    return;
  }

  firestoreRedux.query('board-attachments', { id: requesterId, where: [['boardId', '==', boardId]], requesterId });

  const accountId = router.selectors.accountId(state);
  if(accountId) {
    firestoreRedux.getDocById(`accounts/${accountId}/account-cloud-stores`, `acs_${getIdWoPrefix({ id: accountId, prefix: 'acc_' })}`, { requesterId });
  }

  const userId = auth.selectors.currentUserId(state);
  //If user is not login.
  if (!userId) {
    return;
  }

  firestoreRedux.getDocById(`users/${userId}/cloud-store-emails`, `cse_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId });
  firestoreRedux.query('user-cloud-stores', { where: [['userId', '==', userId]], requesterId });
  firestoreRedux.query('board-attachment-unread-user-details', { where: [['boardId', '==', boardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });

  const accessibleAccounts = auth.selectors.accessibleAccounts(state);
  //If user has no access on the account.
  if (!accountId || !accessibleAccounts || !accessibleAccounts.includes(accountId)) {
    return;
  }

  firestoreRedux.getDocById(`cloud-store-access-restricted`, `csar_${getIdWoPrefix({ id: accountId, prefix: 'acc_' })})}`, { requesterId });
}

/**
 * Disconnects board attachments data from firestore.
 */
function* disconnectBoardAttachments({ boardId }) {
  firestoreRedux.cancelQueryByRequester(`board-attachments-${boardId}`);
}

/**
 * Loads card attachments, account-cloud-stores & cloud-store-emails from firestore.
 */
function* loadCardAttachments({ cardId }) {
  const state = yield select();
  const boardId = router.selectors.pageBoardId(state);

  // If query is live already, returns.
  const requesterId = `card-attachments-${cardId}`;
  const queryStatus = firestoreRedux.selectors.queryStatus(state, requesterId);
  if (queryStatus === 'PENDING' || queryStatus === 'LIVE') {
    return;
  }

  firestoreRedux.query('card-attachments', { id: requesterId, where: [['boardId', '==', boardId], ['cardId', '==', cardId]], requesterId,});

  const accountId = router.selectors.accountId(state);
  if(accountId) {
    firestoreRedux.getDocById(`accounts/${accountId}/account-cloud-stores`, `acs_${getIdWoPrefix({ id: accountId, prefix: 'acc_' })}`, { requesterId});
  }

  const userId = auth.selectors.currentUserId(state);
  //If user is not login.
  if (!userId) {
    return;
  }

  firestoreRedux.getDocById(`users/${userId}/cloud-store-emails`, `cse_${getIdWoPrefix({id: userId, prefix: 'usr_'})}`, { requesterId });
  firestoreRedux.query('user-cloud-stores', { where: [['userId', '==', userId]], requesterId });
  firestoreRedux.query('card-attachment-unread-user-details', { where: [['boardId', '==', boardId], ['cardId', '==', cardId], ['userId', '==', userId], ['anyUnread', '==', true]], requesterId });

  const accessibleAccounts = auth.selectors.accessibleAccounts(state);
  //If user has no access on the account.
  if (!accountId || !accessibleAccounts || !accessibleAccounts.includes(accountId)) {
    return;
  }

  firestoreRedux.getDocById(`cloud-store-access-restricted`, `csar_${getIdWoPrefix({ id: accountId, prefix: 'acc_' })}`, { requesterId });
}

function* disconnectCardAttachments({ cardId }) {
  firestoreRedux.cancelQueryByRequester(`card-attachments-${cardId}`);
}

/**
 * Loads file-info for given `fileId` from firestore.
 */
function* loadFileInfo({ fileId, url }) {
  if (!fileId) {
    console.warn('loadFileInfo > fileEd is not provided');
    return;
  }
  const state = yield select();
  const _store = fileStore.selectors.preferredStorage(state);
  const docId = `${_store}-${fileId}`;
  if(attachmentUtils.isMicrosoftCloudStoreSortLink(url)) {
    firestoreRedux.query('cloud-files', { id: `cloud-files-${docId}`, where: [['sortLink', '==', fileId]], once: true });
    return;
  }
  firestoreRedux.getDocById('cloud-files', docId, { id: `cloud-files-${docId}`, once: true });
}

/**
 * Creates Unique Id.
 * Local writes attachment.
 * Sends request to add new weblink.
 */
function* createWebLink({ boardId, cardId, url }) {
  let id;
  const queryId = cardId ? `card-attachments-${cardId}` : `board-attachments-${boardId}`;
  try {
    id = entityIdProvider.getId();
    id = cardId ? `ca_${id}` : `ba_${id}`;
    const doc = {
      id,
      adding: true,
      boardId,
      cardId,
      title: url,
      contentType: 'text/html',
      type: router.actions.isAppUrl(url) ? 'INTERNAL_LINK' : 'EXTERNAL_LINK',
      subType: 'WEB',
      url,
      order: -Date.now(),
      createdAt: Date.now(),
    };

    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    firestoreRedux.save(collectionPath, doc, { localWrite: true, queryId });
    yield put(knownFeatures.actions.markAsKnown('ADD_NEW_ATTACHMENT'));
    const apiUrl = cardId ? `/attachment/card-attachments/link` : `/attachment/board-attachments/link`;
    const body = { id, boardId, cardId, url };
    yield call(requestApi, apiUrl, { method: 'POST', body });
    yield put({ type: actions.CREATE_WEBLINK_DONE, id, url });
  } catch (error) {
    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    id && firestoreRedux.delete(collectionPath, id, { localWrite: true, queryId });
    yield put({ type: actions.CREATE_WEBLINK_FAILED, id, url });
    console.warn(error);
  }
}

/**
 * Creates Unique Id.
 * Opens document link in new tab.
 * Local writes attachment.
 * Sends request to create new document.
 * When storage access is revoked for current account, then dispatch file store access revoked detected.
 */
function* createDocument({ boardId, cardId, title, docType }) {
  if (!boardId || !title || !docType) {
    throw new Error('Passed parameters are wrong..');
  }

  let id;
  const state = yield select();
  const queryId = cardId ? `card-attachments-${cardId}` : `board-attachments-${boardId}`;
  const preferredStoreSelectionPending = () => {
    const callback = function () {
      store.dispatch(actions.createDocument({ boardId, cardId, title, docType }));
    };
    store.dispatch(fileStore.actions.preferredStoreSelectionPending({ callback }));
  };

  try {
    //If preferred store selection is pending.
    if (docType !== 'CANVAS' && !fileStore.selectors.preferredStoreSelectionDone(state)) {
      setTimeout(() => {
        preferredStoreSelectionPending();
      }, 100);
      return;
    }

    id = entityIdProvider.getId();
    id = cardId ? `ca_${id}` : `ba_${id}`;

    if (docType !== 'CANVAS' && !window.navigator.userAgent.includes(window.K.config.installedAppUserAgent)) {
      window.open(`${location.origin}/attachment.html?id=${id}`, '_blank');
    }

    const _store = fileStore.selectors.preferredStorage(state);
    const _isGoogleStorage = _store === 'GOOGLE' || _store === 'KERIKA' || _store === 'GOOGLE_MARKET_PLACE';
    if(!_isGoogleStorage && docType === 'GOOGLE_FORM') {
      console.warn("Google forms cannot be created for other than Google Storage.")
      return;
    }
    
    const _isBoxStorage = _store === 'BOX';
    if(!_isBoxStorage && docType === 'BOX_NOTE') {
      console.warn("Box notes cannot be created for other than Box Storage.")
      return;
    }

    const contentType =
      docType == 'SPREADSHEET'
        ? 'file/spreadsheet'
        : docType == 'PRESENTATION'
        ? 'file/presentation'
        : docType == 'GOOGLE_FORM'
        ? 'file/form'
        : docType == 'BOX_NOTE' 
        ? 'file/other': 
        docType == 'ONE_NOTE'
        ? 'file/onenote'
        : 'file/document';

    //first attachment tip is shown to user.
    const hasTipItem = cardId ? selectors.hashCardAttachmentsTipItem({state, cardId}) : selectors.hashBoardAttachmentsTipItem({state, boardId});
    const tip = !hasTipItem && knownFeatures.selectors.firstAttachmentCreatedTipName(state) || '';

    const doc = {
      id,
      creating: true,
      boardId,
      cardId,
      title,
      type: 'FILE',
      subType: docType,
      contentType,
      order: -Date.now(),
      createdAt: Date.now(),
      coludPermissionTip: tip
    };
    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    firestoreRedux.save(collectionPath, doc, { localWrite: true, queryId });

    yield put(knownFeatures.actions.markAsKnown('ADD_NEW_ATTACHMENT'));
    const apiUrl = cardId
      ? docType === 'CANVAS'
        ? `/attachment/card-attachments/canvas`
        : `/attachment/card-attachments/file`
      : docType === 'CANVAS'
      ? `/attachment/board-attachments/canvas`
      : `/attachment/board-attachments/file`;
    const body = { id, boardId, cardId, title, fileType: docType };
    const response = yield call(requestApi, apiUrl, { method: 'POST', body, excludeErrors: [400, 409] });

    //Open canvas attachments.
    if (docType === 'CANVAS') {
      const accountId = router.selectors.accountId(state);
      const boardId = router.selectors.pageBoardId(state);
      const referenceEntityId = response && response.referenceEntityId;
      if (referenceEntityId) {
        router.actions.navigate(`/${accountId}/board/${boardId}/cnvs_${routerUtils.to64(+referenceEntityId)}`);
      }
    }
  } catch (err) {
    console.warn(err);
    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    id && firestoreRedux.delete(collectionPath, id, { localWrite: true, queryId });
    //If failed due to network error or abort request .
    if (isNetworkError(err)) {
      return;
    }

    if(err.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }

    if (err.code === 'TITLE_IS_TOO_LONG') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.longName'), type: 'ERROR' });
      return;
    }

    if (err.code === 'CLOUD_STORE_ACCESS_REVOKED' || err.code === 'PASSWORD_RESET_REQUIRED') {
      yield put({ type: fileStore.actions.ACCESS_REVOKED_DETECTED, code: err.code });
      return;
    }

    if (err.code === 'PREFERRED_STORE_SELECTION_PENDING') {
      preferredStoreSelectionPending();
      return;
    }

    console.error('Create document is failed, due to this error: ', err);
  }

  if (docType !== 'CANVAS' && window.navigator.userAgent.includes(window.K.config.installedAppUserAgent)) {
    id && window.open(`${location.origin}/attachment.html?id=${id}`, '_blank');
  }
}

/**
 * If given file size is greater then file max size in MB size then shows an error toast message and does nothing.
 * Localwrites attachment.
 * Sends request to upload new file.
 * When storage access is revoked for current account, then dispatch file store access revoked detected.
 * @param {*}
 *  @property {*} file give a file to upload into container.
 *  @property {String} boardId Board Id
 *  @property {String} cardId If attachment is board level then `root` otherwise `cardId`
 */
function* uploadFile({ file, boardId, cardId }) {
  const state = yield select();
  const queryId = cardId ? `card-attachments-${cardId}` : `board-attachments-${boardId}`;
  const preferredStoreSelectionPending = () => {
    const callback = function () {
      store.dispatch(actions.uploadFile({ file, boardId, cardId }));
    };
    store.dispatch(fileStore.actions.preferredStoreSelectionPending({ callback }));
  };

  //If preferred store selection is pending.
  if (!fileStore.selectors.preferredStoreSelectionDone(state)) {
    setTimeout(() => {
      preferredStoreSelectionPending();
    }, 100);
    return;
  }

  const fileSizeInMB = file.size / 1024 / 1024;
  const appConfig = app.selectors.config(state);
  const uploadFileMaxSizeInMb = get(appConfig, 'attachment.file.maxSizeInMb');

  if (uploadFileMaxSizeInMb && fileSizeInMB > uploadFileMaxSizeInMb) {
    showSnackBar({
      message: i18next.t('attachments:uploadFile.errorMessage', { size: uploadFileMaxSizeInMb }),
      type: 'ERROR',
    });
    return;
  }

  let id = entityIdProvider.getId();
  id = cardId ? `ca_${id}` : `ba_${id}`;
  const abortController = new AbortController();

  //first attachment tip is shown to user.
  const hasTipItem = cardId ? selectors.hashCardAttachmentsTipItem({state, cardId}) : selectors.hashBoardAttachmentsTipItem({state, boardId});
  const tip = !hasTipItem && knownFeatures.selectors.firstAttachmentCreatedTipName(state) || '';

  try {
    const doc = {
      id,
      uploading: true,
      cardId,
      boardId,
      contentType: file.type || 'file/other',
      type: 'FILE',
      title: file.name,
      order: -Date.now(),
      createdAt: Date.now(),
      abortController,
      coludPermissionTip: tip
    };

    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    firestoreRedux.save(collectionPath, doc, { localWrite: true, queryId });

    abortController.signal.onabort = function () {
      firestoreRedux.delete(collectionPath, id, { localWrite: true });
    };

    yield put(knownFeatures.actions.markAsKnown('ADD_NEW_ATTACHMENT'));

    const apiUrl = cardId ? `/attachment2/card-attachments/upload-file` : `/attachment2/board-attachments/upload-file`;

    let formdata = new FormData();
    formdata.append('id', id);
    formdata.append('boardId', boardId);
    formdata.append('cardId', cardId);
    formdata.append('file', file);
    formdata.append('title', file.name);
    formdata.append('fileContentLength', file.size);

    const options = {
      method: 'POST',
      body: formdata,
      signal: abortController.signal,
      excludeErrors: [400, 409],
    };
    yield call(requestApi, apiUrl, options);

    yield put({ type: actions.UPLOAD_FILE_DONE, boardId, cardId });
  } catch (error) {
    const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
    firestoreRedux.delete(collectionPath, id, { localWrite: true, queryId });

    if (isNetworkError(error) || error.code == error.ABORT_ERR) {
      return;
    }

    yield put({ type: actions.UPLOAD_FILE_FAILED, boardId, cardId });

    if(error.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }

    if (error.code === 'TITLE_IS_TOO_LONG') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.longName'), type: 'ERROR' });
      return;
    }

    if (error.code === 'CLOUD_STORE_ACCESS_REVOKED' || error.code === 'PASSWORD_RESET_REQUIRED') {
      yield put({ type: fileStore.actions.ACCESS_REVOKED_DETECTED, code: error.code });
      return;
    }

    if (error.code === 'PREFERRED_STORE_SELECTION_PENDING') {
      preferredStoreSelectionPending();
      return;
    }

    console.error('upload file is failed, due to this error: ', error);
  }
}

/**
 * When 1 of the file exceed maximum sizes, doesn't do anything.
 * If files with same name exists, uploads it's new version otherwise uploads new file.
 * When attachment is card level & it's attached by drag & drop, opens card's attachment tab.
 * @param {*} param0
 *  @property {*} files Files selected by user.
 *  @property {String} boardId Board Id.
 *  @property {String} cardId Card Id. It's optional.
 *  @property {Boolean} dragndrop When user is uploading files through drag & drop, it should be `true`.
 */
function* uploadFiles({ files, boardId, cardId, dragndrop }) {
  let state = yield select();
  const appConfig = app.selectors.config(state);
  const uploadFileMaxSizeInMb = get(appConfig, 'attachment.file.maxSizeInMb');
  let largeFile = false;
  forEach(files, (file) => {
    if (uploadFileMaxSizeInMb && file.size / 1024 / 1024 > uploadFileMaxSizeInMb) {
      largeFile = true;
      return false;
    }
  });

  if (largeFile) {
    showSnackBar({
      message: i18next.t('attachments:uploadFile.errorMessage', { size: uploadFileMaxSizeInMb }),
      type: 'ERROR',
    });
    return;
  }

  try {
    yield call(waitTillAttachmentDataAvailable, boardId, cardId);
    state = yield select();
    const allAttachments = cardId
      ? firestoreRedux.selectors.collection(state, 'card-attachments')
      : firestoreRedux.selectors.collection(state, 'board-attachments');
    const attachments = cardId ? filter(allAttachments, { cardId }) : filter(allAttachments, { boardId });
    forEach(files, (file) => {
      const attachment = find(attachments, { title: file.name });
      if (attachment && attachment.id) {
        store.dispatch(actions.uploadNewVersion({ file, boardId, cardId, id: attachment.id }));
      } else {
        store.dispatch(actions.uploadFile({ file, boardId, cardId }));
      }
    });

    if (cardId && dragndrop) {
      const accountId = router.selectors.accountId(state);
      const dialogName = router.selectors.dialogName(state);
      router.actions.navigate(`/${accountId}/board/${boardId}/${cardId}?tab=attachments`, dialogName === 'CARD');
    }
    yield put({ type: actions.UPLOAD_FILES_DONE, boardId, cardId });
  } catch (error) {
    yield put({ type: actions.UPLOAD_FILES_FAILED, boardId, cardId });
    console.warn(`Failed to upload files`, error);
  }
}

/**
 * Wait till attachments data available.
 */
function waitTillAttachmentDataAvailable(boardId, cardId) {
  const state = store.getState();
  const id = cardId ? `card-attachments-${cardId}`: `board-attachments-${boardId}`;
  const queryStatus = firestoreRedux.selectors.queryStatus(state, id);
  if (queryStatus === 'LIVE') {
    return;
  }

  let resolve, reject;
  cardId ? store.dispatch(actions.loadCardAttachments(cardId)): store.dispatch(actions.loadBoardAttachments(boardId));
  const promise = new Promise((res, rej) => { resolve = res, reject = rej; });
  const unsubscribe = ReduxUtils.subscribe(store, `firestore.queries.${id}.status`, (queryStatus)=> {
    if (queryStatus === 'LIVE') {
      unsubscribe && unsubscribe();
      resolve();
    }
  });
  return promise;
}

/**
 * If given file size is greater then file max size in MB size then shows an error toast message and does nothing.
 * Localwrites attachment.
 * Sends request to upload new version of the file.
 * When storage access is revoked for current account, then dispatch file store access revoked detected.
 * @param {*}
 *  @property {*} file give a file to upload into container.
 *  @property {String} boardId Board Id
 *  @property {String} cardId If attachment is board level then `root` otherwise `cardId`
 *  @property {String} id Attachment Id. If it's given, upload it's new version.
 */
function* uploadNewVersion({ file, boardId, cardId, id }) {
  const state = yield select();

  const preferredStoreSelectionPending = () => {
    const callback = function () {
      store.dispatch(actions.uploadNewVersion({ file, boardId, cardId, id }));
    };
    store.dispatch(fileStore.actions.preferredStoreSelectionPending({ callback }));
  };

  //If preferred store selection is pending.
  if (!fileStore.selectors.preferredStoreSelectionDone(state)) {
    setTimeout(() => {
      preferredStoreSelectionPending();
    }, 100);
    return;
  }

  const fileSizeInMB = file.size / 1024 / 1024;
  const appConfig = app.selectors.config(state);
  const uploadFileMaxSizeInMb = get(appConfig, 'attachment.file.maxSizeInMb');

  // returns if size exceeds maximum limit.
  if (uploadFileMaxSizeInMb && fileSizeInMB > uploadFileMaxSizeInMb) {
    showSnackBar({
      message: i18next.t('attachments:uploadFile.errorMessage', { size: uploadFileMaxSizeInMb }),
      type: 'ERROR',
    });
    return;
  }

  const abortController = new AbortController();
  const collection = cardId ? `card-attachments` : `board-attachments`;
  const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
  const currentDoc = firestoreRedux.selectors.doc(state, collection, id);
  try {
    const doc = { ...currentDoc, title: file.name, updatedAt: Date.now(), newVersionUpload: true, abortController };
    firestoreRedux.save(collectionPath, doc, { localWrite: true });

    abortController.signal.onabort = function () {
      firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    };

    const apiUrl = cardId ? `/attachment2/card-attachments/${id}` : `/attachment2/board-attachments/${id}`;
    let formdata = new FormData();

    formdata.append('boardId', boardId);
    formdata.append('cardId', cardId);
    formdata.append('file', file);
    formdata.append('title', file.name);
    formdata.append('fileContentLength', file.size);

    const options = {
      method: 'POST',
      body: formdata,
      signal: abortController.signal,
      excludeErrors: [400, 409],
    };
    yield call(requestApi, apiUrl, options);
    yield put({ type: actions.UPLOAD_NEW_VERSION_DONE, boardId, cardId, id });
  } catch (error) {
    firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });

    if (isNetworkError(error) || error.code == error.ABORT_ERR) {
      return;
    }

    yield put({ type: actions.UPLOAD_NEW_VERSION_FAILED, boardId, cardId, id });

    if(error.code === 'FILE_NOT_SUPPORTED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileNotSupported'), type: 'ERROR' });
      return;
    }

    if(error.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }

    if (error.code === 'TITLE_IS_TOO_LONG') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.longName'), type: 'ERROR' });
      return;
    }

    if (error.code === 'CLOUD_STORE_ACCESS_REVOKED' || error.code === 'PASSWORD_RESET_REQUIRED') {
      yield put({ type: fileStore.actions.ACCESS_REVOKED_DETECTED, code: error.code });
      return;
    }

    if (error.code === 'PREFERRED_STORE_SELECTION_PENDING') {
      preferredStoreSelectionPending();
      return;
    }

    console.error('upload new version is failed, due to this error: ', error);
  }
}

/**
 * Updates title of the given attachment with localWrite.
 * @param {*} param0
 *  @property {String} id Attachment Id
 *  @property {String} boardId Board Id for board attachment
 *  @property {String} cardId Card Id for card attachment.
 *  @property {String} title New title of the attachment.
 */
function* rename({ id, boardId, cardId, title }) {
  const state = yield select();
  const collection = cardId ? `card-attachments` : `board-attachments`;
  const currentDoc = firestoreRedux.selectors.doc(state, collection, id);
  const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;

  try {
    const newDoc = { ...currentDoc, title };
    firestoreRedux.save(collectionPath, newDoc, { localWrite: true });

    const apiUrl = cardId ? `/attachment/card-attachments/${id}/title` : `/attachment/board-attachments/${id}/title`;
    const options = {
      method: 'PUT',
      body: { title },
      excludeErrors: [400, 409]
    };
    yield call(requestApi, apiUrl, options);
    yield put({ type: actions.RENAME_DONE, id, boardId, cardId });
  } catch (error) {
    firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    yield put({ type: actions.RENAME_FAILED, id, boardId, cardId });
    //If failed due to network error or abort request .
    if (isNetworkError(error) || error.code == error.ABORT_ERR) {
      return;
    }

    if(error.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }

    if (error.code === 'TITLE_IS_TOO_LONG') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.longName'), type: 'ERROR' });
      return;
    }

    console.error('Rename attachment is failed, due to this error: ', error);
  }
}

/**
 * Deletes the attachment
 * @param {*} param0
 *  @property {String} id Attachment Id.
 *  @property {String} cardId Card Id. When it's not given, considers board attachment.
 */
function* del({ id, cardId, boardId }) {
  const state = yield select();
  boardId = boardId || router.selectors.pageBoardId(state);
  const collection = cardId ? `card-attachments` : `board-attachments`;
  const currentDoc = firestoreRedux.selectors.doc(state, collection, id);
  const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
  try {
    const newDoc = { ...currentDoc, deleted: true };
    firestoreRedux.save(collectionPath, newDoc, { localWrite: true });
    const apiUrl = cardId ? `/attachment/card-attachments/${id}` : `/attachment/board-attachments/${id}`;
    yield call(requestApi, apiUrl, { method: 'DELETE' , excludeErrors: [409]});
  } catch (error) {
    firestoreRedux.save(collectionPath, currentDoc, { localWrite: true });
    if (isNetworkError(error)) {
      return;
    }

    if(error.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }
    console.error('Failed to delete attachment', error);
  }
}

/**
 * restore the attachment
 * @param {*} param0
 *  @property {String} id Attachment Id.
 *  @property {String} cardId Card Id. When it's not given, considers board attachment.
 */
function* restore({ id, cardId }) {
  try {
    const apiUrl = cardId ? `/attachment/card-attachments/canvas/${id}` : `/attachment/board-attachments/canvas/${id}`;
    yield call(requestApi, apiUrl, { method: 'POST' });
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }
    console.error('Failed to restore attachment', error);
  }
}

/**
 * If installed app, gets download link through API & opens that link as an external link otherwie, downloads directly from server API.
 * @param {*} param0
 *  @property {String} cardId Card Id. It's optional. If not given, considers board attachment.
 *  @property {String} id Attachment Id.
 */
function* download({ cardId, id }) {
  const state = yield select();
  const installedApp = app.selectors.isInstalledApp(state);
  const config = app.selectors.config(state);
  if (!installedApp) {
    const url = cardId ? `/file-store/card-attachments/${id}/download` : `/file-store/board-attachments/${id}/download`;
    downloadUrl(config.apiBaseUrl + url);
    return;
  }

  try {
    const url = cardId ? `/file-store/card-attachments/${id}/download-link` : `/file-store/board-attachments/${id}/download-link`;
    const accountId = router.selectors.accountId(state);
    const boardId = router.selectors.pageBoardId(state);
    const cardOrCanvaseId = router.selectors.cardId(state);
    const body = { accountId, boardId, cardOrCanvaseId };
    const response = yield call(requestApi, url, { method: 'POST', body, excludeErrors: [409] });
    const downloadLink = response.link;
    if(downloadLink) {
      const link = `${config.webAppBaseUrl}/en/open-external-link.html?redirect=${encodeURIComponent(downloadLink)}`;
      openExternalLink({ href: link }, id);
    } else {
      console.error('attachemnt > download failed due to this: download link is not found');
    }
  } catch (error) {
    if (isNetworkError(error)) {
      return;
    }

    if(error.code === 'FILE_LOCKED') {
      showSnackBar({ message: i18next.t('attachments:errorMessage.fileLock'), type: 'ERROR' });
      return;
    }

    if (error.code === 'CLOUD_STORE_ACCESS_REVOKED' || error.code === 'PASSWORD_RESET_REQUIRED') {
      yield put({ type: fileStore.actions.ACCESS_REVOKED_DETECTED, code: error.code });
      return;
    }

    console.error('attachemnt > download failed due to this: ', error);
  }
}

/*
 * Computes new order of the item.
 * Localwrites updated doc.
 * Requests server to update order of the attachment.
 * @param {Object} oParams
 *  - @property {String} boardId Give the id of the board in which you want to move attachments.
 *  - @property {String} cardId Card Id. It is optional. When it's not provided, it considers board attachment.
 *  - @property {String} id Give the id of moved attachment.
 *  - @property {Number} newIndex New order of moved attachement.
 */
function* move({ boardId, cardId, id, newIndex }) {
  const state = yield select();
  const collection = cardId ? `card-attachments` : `board-attachments`;
  const collectionPath = cardId ? `boards/${boardId}/cards/${cardId}/card-attachments` : `boards/${boardId}/board-attachments`;
  const attachments = cardId ? selectors.cardAttachments({ state, cardId }) : selectors.boardAttachments({ state, boardId });
  const oldIndex = findIndex(attachments, { id });
  const prevDoc = get(attachments, `${newIndex > oldIndex ? newIndex : newIndex - 1}`);

  const before = get(prevDoc, `id`);
  const currentDoc = firestoreRedux.selectors.doc(state, collection, id);
  const newDocs = getNewOrder({ allItems: attachments, movedItems: [currentDoc], before });

  let attachmentWiseOrder = {};
  forEach(newDocs, (doc) => {
    attachmentWiseOrder[doc.id] = doc.order;
  });

  if (isEmpty(attachmentWiseOrder)) {
    console.warn('No attachment found to be moved');
    return;
  }
  try {
    // Local write.
    yield firestoreRedux.save(collectionPath, newDocs, { localWrite: true });
    const apiUrl = cardId ? `/attachment/card-attachments/move` : `/attachment/board-attachments/move`;
    // Request options.
    const options = {
      method: 'PUT',
      body: { attachmentWiseOrder },
    };
    yield call(requestApi, apiUrl, options);
  } catch (error) {
    firestoreRedux.save(collectionPath, attachments, { localWrite: true });
    console.error('Failed to move attachment', error);
  }
}

/**
 * Refreshes content.updatedAt/updatedBy detail for the card/board attachments whose type is FILE.
 * @param {*} param0
 *  @property {String} boardId Board Id
 *  @property {String} cardId Card Id.
 */
function* refreshContentDetail({ boardId, cardId }) {
  const state = yield select();
  const allAttachments = cardId ? selectors.cardAttachments({ state, cardId }) : selectors.boardAttachments({ state, boardId });
  const fileAttachments = filter(allAttachments, { type: 'FILE' });
  const ids = map(fileAttachments, 'id');
  const impersonatedUser = auth.selectors.impersonatedUser(state);

  if (impersonatedUser || isEmpty(ids)) {
    return;
  }

  try {
    const url = cardId ? `/attachment/card-attachments/refresh-content-detail` : `/attachment/board-attachments/refresh-content-detail`;
    requestApi(url, { method: 'PUT', body: { ids }, excludeErrors: [400, 409] });
  } catch (error) {
    console.error(`Failed to refresh content details`, error, { boardId, cardId });
  }
}

/**
 * Open attachment.
 */
function* _openAttachment(action) {
  try {
    let url = action && action.url;
    if (!url || !action) {
      console.error('attachments > _openAttachment > Invalid arguments');
      return;
    }

    const state = yield select();
    const boardId = action.boardId || router.selectors.pageBoardId(state);
    const attachementId = action.id;
    let type = action.attachmentType || 'EXTERNAL_LINK';

    type = type === 'LINK' ? (router.actions.isAppUrl(url) ? 'INTERNAL_LINK' : type) : type;

    //If current url is in app url.
    if (type === 'INTERNAL_LINK') {
      url = router.actions.getPWAUrl(url);
      const isSameUrl = router.actions.isSameUrlPath(url);
      router.actions.navigate(url, isSameUrl);
      return;
    }

    //If attachment type is not
    if (type != 'FILE') {
      openExternalLink({ href: url }, attachementId);
      return;
    }

    const cloudStoreAccessRestricted = fileStore.selectors.cloudStoreAccessRestricted(state);
    const boardFolderShared = selectors.isBoardFolderShared(state);

    //If user has alredy permission to acces a store file.
    if (cloudStoreAccessRestricted !== true) {
      // If board foleder is not shared with current user & board is not public.
      const boardPrivacy = board.selectors.privacy(state, boardId);
      if (!boardFolderShared && boardPrivacy !== 'PUBLIC') {
        router.actions.setQueryParams({ 'file-preview': attachementId }, true);
        return;
      }

      openExternalLink({ href: url }, attachementId, false);
      return;
    }

    const res = yield store.dispatch(actions.userHasAccessOnStore());

    if (res === true) {
      openExternalLink({ href: url }, attachementId, false);
      return;
    }
    router.actions.setActionParam('store-restricted');
  } catch (error) {
    console.error('attachments > _openAttachment > failed due to this: ', error);
  }
}

/**
 * Init Saga.
 */
function* saga() {
  yield all([
    takeEvery(auth.actions.LINK_ADDITIONAL_AUTH_SUCCESS, onLinkAdditionalAuthSuccess),
    takeEvery(actions.LOAD_BOARD_ATTACHMENTS, loadBoardAttachments),
    takeEvery(actions.DISCONNECT_BOARD_ATTACHMENTS, disconnectBoardAttachments),
    takeEvery(actions.LOAD_CARD_ATTACHMENTS, loadCardAttachments),
    takeEvery(actions.DISCONNECT_CARD_ATTACHMENTS, disconnectCardAttachments),
    takeEvery(actions.LOAD_FILE_INFO, loadFileInfo),
    takeEvery(actions.CREATE_WEBLINK, createWebLink),
    takeEvery(actions.CREATE_DOCUMENT, createDocument),
    takeEvery(actions.UPLOAD_FILE, uploadFile),
    takeEvery(actions.UPLOAD_FILES, uploadFiles),
    takeEvery(actions.UPLOAD_NEW_VERSION, uploadNewVersion),
    takeEvery(actions.RENAME, rename),
    takeEvery(actions.DELETE, del),
    takeEvery(actions.RESTORE, restore),
    takeEvery(actions.DOWNLOAD, download),
    takeEvery(actions.MOVE, move),
    takeEvery(actions.REFRESH_CONTENT_DETAIL, refreshContentDetail),
    takeEvery(actions.OPEN_ATTACHMENT, _openAttachment)
  ]);
}

export default saga;
